import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import TextCleaner from './components/TextCleaner';
import { initGA, logPageView } from './analytics';
import './App.css';

function App() {
  useEffect(() => {
    initGA('G-7LN7E46E11');
    logPageView();
  }, []);

  return (
    <>
      <Helmet>
        <script>{`
          (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"73008296"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
        `}</script>
      </Helmet>
      <Container maxWidth="md">
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h2" component="h1" gutterBottom>
            CleanMyText
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            CleanMyText is a powerful online tool for cleaning and formatting text. Easily remove email indents, change text case, and improve your text's readability with just a few clicks.
          </Typography>
        </Box>
        <TextCleaner />
      </Container>
    </>
  );
}

export default App;

