import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';

const TextCleaner = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [emailIndents, setEmailIndents] = useState('doNothing');
  const [textCase, setTextCase] = useState('doNothing');
  // Add more state variables for other options

  const handleCleanText = () => {
    let cleanedText = inputText;

    // Implement cleaning logic based on selected options
    if (emailIndents === 'remove') {
      cleanedText = cleanedText.replace(/^[>\s]+/gm, '');
    }

    switch (textCase) {
      case 'lowercase':
        cleanedText = cleanedText.toLowerCase();
        break;
      case 'uppercase':
        cleanedText = cleanedText.toUpperCase();
        break;
      case 'capitalize':
        cleanedText = cleanedText.replace(/\b\w/g, c => c.toUpperCase());
        break;
      case 'sentence':
        cleanedText = cleanedText.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, c => c.toUpperCase());
        break;
      default:
        break;
    }

    setOutputText(cleanedText);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          minRows={3}
          maxRows={10}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          label="Paste Text to clean"
          variant="outlined"
          sx={{ 
            '& .MuiInputBase-root': {
              paddingTop: '8px',
            },
            '& .MuiInputLabel-root': {
              top: '-6px',
            },
            '& .MuiInputLabel-shrink': {
              top: 0,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="email-indents-label">Email Indents</InputLabel>
          <Select
            labelId="email-indents-label"
            value={emailIndents}
            onChange={(e) => setEmailIndents(e.target.value)}
            label="Email Indents"
          >
            <MenuItem value="doNothing">Do Nothing</MenuItem>
            <MenuItem value="remove">Remove</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="text-case-label">Text Case</InputLabel>
          <Select
            labelId="text-case-label"
            value={textCase}
            onChange={(e) => setTextCase(e.target.value)}
            label="Text Case"
          >
            <MenuItem value="doNothing">Do Nothing</MenuItem>
            <MenuItem value="lowercase">All Lowercase</MenuItem>
            <MenuItem value="uppercase">All Uppercase</MenuItem>
            <MenuItem value="capitalize">Capitalize All Words</MenuItem>
            <MenuItem value="sentence">Sentence Case</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* Add more option selectors here */}
      <Grid item xs={12}>
        <Button 
          fullWidth 
          variant="contained" 
          onClick={handleCleanText} 
          sx={{ mt: 2, mb: 2 }}
        >
          Clean Text
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          minRows={3}
          maxRows={10}
          value={outputText}
          label="Cleaned Text"
          InputProps={{
            readOnly: true,
          }}
          sx={{ 
            '& .MuiInputBase-root': {
              paddingTop: '8px',
            },
            '& .MuiInputLabel-root': {
              top: '-6px',
            },
            '& .MuiInputLabel-shrink': {
              top: 0,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TextCleaner;
